import React, { useEffect } from "react";
import blechmuecke2014 from "../../../resources/images/artsmall/Blechmücke 2014.JPG";
import blumen from "../../../resources/images/artsmall/Blumen.JPG";
import bogenschuetze2015 from "../../../resources/images/artsmall/Bogenschütze 2015.JPG";
import bueste2021 from "../../../resources/images/artsmall/Bueste 2021.JPG";
import changel from "../../../resources/images/artsmall/ChAngel.jpg";
import depri from "../../../resources/images/artsmall/Depri.jpg";
import elchBlankstahl from "../../../resources/images/artsmall/Elch Blankstahl.jpg";
import elchCortenstahl from "../../../resources/images/artsmall/Elch Cortenstahl.jpg";
import flamingo from "../../../resources/images/artsmall/Flamingo.jpeg";
import galaxie1 from "../../../resources/images/artsmall/Galaxie 1.jpg";
import gottesanbeterinnen2016 from "../../../resources/images/artsmall/Gottesanbeterinnen 2016.jpg";
import hasen from "../../../resources/images/artsmall/Hasen.jpg";
import heuschnecke from "../../../resources/images/artsmall/Heuschnecke.JPG";
import heuschrecke from "../../../resources/images/artsmall/Heuschrecke.JPG";
import iRobert2018 from "../../../resources/images/artsmall/I Robert 2018.jpg";
import impala2021 from "../../../resources/images/artsmall/Impala 2021.jpg";
import kastenflosser2019 from "../../../resources/images/artsmall/Kastenflosser 2019.jpg";
import longhorn from "../../../resources/images/artsmall/Longhorn 2021.jpg";
import pteranodon2013 from "../../../resources/images/artsmall/Pteranodon 2013.JPG";
import rostSpanner from "../../../resources/images/artsmall/Rostspanner.jpg";
import schmetterling from "../../../resources/images/artsmall/Schmetterling blank.jpg";
import springer from "../../../resources/images/artsmall/Springer.jpg";
import drache from "../../../resources/images/artsmall/Drache 2024.png";
import herzAmStiel from "../../../resources/images/artsmall/HerzAmStiel.jpg";
import herabschauenderMensch from "../../../resources/images/artsmall/HerabschauenderMensch.jpg";
import shame from "../../../resources/images/artsmall/Shame.jpg";
import silhouette from "../../../resources/images/artsmall/Silhouette.jpg";
import stegosaurus2011 from "../../../resources/images/artsmall/Stegosaurus 2011.JPG";
import swingCircle from "../../../resources/images/artsmall/SwingCircle.jpeg";
import tRex2012 from "../../../resources/images/artsmall/TRex 2012.JPG";
import taenzerin1_2021 from "../../../resources/images/artsmall/Tänzerin 1 2021.jpg";
import taenzerin2_2021 from "../../../resources/images/artsmall/Tänzerin 2 2021.jpg";
import yoda2InCorten2017 from "../../../resources/images/artsmall/Yoda 2 in Corten2017.jpg";
import { Gallery } from "../../shared/gallery/Gallery";

export const ArtGallery = ({ onMount }) => {

    useEffect(() => {
        onMount("\"(H)ART\"");
    }, []);


    return (
        <Gallery images={images} />
    )
}

const images = [
    {
        id: 1,
        imgSrc: blechmuecke2014,
        title: "Blechmücke",
        description: "2014"
    },
    {
        id: 2,
        imgSrc: bogenschuetze2015,
        title: "Bogenschütze",
        description: "2015"
    },
    {
        id: 3,
        imgSrc: galaxie1,
        title: "Galaxie 1",
        description: "2019"
    },
    {
        id: 4,
        imgSrc: gottesanbeterinnen2016,
        title: "Gottesanbeterinnen",
        description: "2016"
    },
    {
        id: 5,
        imgSrc: heuschnecke,
        title: "Heuschnecke",
        description: "2018"
    },
    {
        id: 6,
        imgSrc: heuschrecke,
        title: "Heuschrecke",
        description: "2018"
    },
    {
        id: 7,
        imgSrc: iRobert2018,
        title: "I Robert",
        description: "2018"
    },
    {
        id: 8,
        imgSrc: impala2021,
        title: "Impala",
        description: "2021"
    },
    {
        id: 9,
        imgSrc: kastenflosser2019,
        title: "Kastenflosser",
        description: "2019"
    },
    {
        id: 10,
        imgSrc: pteranodon2013,
        title: "Pteranodon",
        description: "2013"
    },
    {
        id: 11,
        imgSrc: stegosaurus2011,
        title: "Stegosaurus",
        description: "2011"
    },
    {
        id: 12,
        imgSrc: tRex2012,
        title: "TRex",
        description: "2012"
    },
    {
        id: 13,
        imgSrc: taenzerin1_2021,
        title: "Tänzerin 1",
        description: "2021"
    },
    {
        id: 14,
        imgSrc: taenzerin2_2021,
        title: "Tänzerin 2",
        description: "2021"
    },
    {
        id: 15,
        imgSrc: longhorn,
        title: "Longhorn",
        description: "2021"
    },
    {
        id: 16,
        imgSrc: yoda2InCorten2017,
        title: "Yoda Corten",
        description: "2017"
    },
    {
        id: 17,
        imgSrc: blumen,
        title: "Blumen",
        description: ""
    },
    {
        id: 19,
        imgSrc: elchBlankstahl,
        title: "Elch Blankstahl",
        description: "2020"
    },
    {
        id: 20,
        imgSrc: elchCortenstahl,
        title: "Elch Cortenstahl",
        description: "2020"
    },
    {
        id: 21,
        imgSrc: hasen,
        title: "Hasen",
        description: ""
    },
    {
        id: 22,
        imgSrc: bueste2021,
        title: "Büste",
        description: "2021"
    },
    {
        id: 23,
        imgSrc: flamingo,
        title: "Flamingo",
        description: "2022"
    },
    {
        id: 24,
        imgSrc: swingCircle,
        title: "Swinging Circle",
        description: "2022"
    },
    {
        id: 25,
        imgSrc: schmetterling,
        title: "Schmetterling Blankstahl",
        description: "2022"
    },
    {
        id: 26,
        imgSrc: rostSpanner,
        title: "Rostspanner Corten",
        description: "2022"
    },
    {
        id: 27,
        imgSrc: changel,
        title: "ChAngel Blank & Corten",
        description: "2019"
    },
    {
        id: 28,
        imgSrc: depri,
        title: "Depri",
        description: "2023"
    },
    {
        id: 29,
        imgSrc: springer,
        title: "Springer",
        description: "2023"
    },
    {
        id: 30,
        imgSrc: drache,
        title: "Drache",
        description: "2024"
    },
    {
        id: 31,
        imgSrc: herzAmStiel,
        title: "Herz am Stiel",
        description: "2024"
    },
    {
        id: 32,
        imgSrc: shame,
        title: "Shame on you",
        description: "2024"
    },
    {
        id: 33,
        imgSrc: silhouette,
        title: "Silhouette",
        description: "2024"
    },
    {
        id: 34,
        imgSrc: herabschauenderMensch,
        title: "Herabschauender Mensch",
        description: "2024"
    }
]
import React from "react";
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";

import ("./PageFooter.css")

export const PageFooter = () => {
    return (
        <footer>
            <Link to={"/impressum"}>
                <Typography variant={"body1"}>
                    Impressum
                </Typography>
            </Link>
            <Link to={"/privacy"}>
                <Typography variant={"body1"}>
                    Datenschutz
                </Typography>
            </Link>
        </footer>
    )
}
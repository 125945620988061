import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import MailOutlined from "../../../resources/icons/mail outlined.png";
import logo from "../../../resources/images/logo2.png";

import("./Contact.css")

export const Contact = ({ onMount }) => {
    useEffect(() => {
        onMount("Kontakt")
    });

    return (
        <div className="contact-container">
            <img className="logo" alt="main logo" src={logo} />
            <Typography variant="h6" align="center">
                Bei Fragen, Anregungen und Kritik
            </Typography>
            <Typography variant="h6" align="center">
                -
            </Typography>
            <Typography variant="h5" align="center">
                Kontaktiere uns!
            </Typography>
            <div className="contact-method-wrapper">
                <img className="contact-method-icon" alt="Mail" src={MailOutlined} />
                <Typography variant="body1" align="center">
                    R. Zink: hart@hizis.de
                </Typography>
            </div>
        </div>
    );
}

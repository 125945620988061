import React, {useEffect} from "react";

import ("./Impressum.css")

export const Impressum = ({onMount}) => {
    useEffect(() => {
        onMount("Impressum")
    }, []);
    return (
        <div className="impressum-container">
            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Johannes Zink<br />
                Mariama-B&acirc;-Stra&szlig;e 20<br />
                65462 Ginsheim</p>

            <h2>Kontakt</h2>
            <p>Telefon: 015779027031<br />
                E-Mail: hannes@hizis.de</p>

            <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>

            <div>Icons erstellt von <a href="https://www.flaticon.com/de/autoren/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a></div>
        </div>
    );
}
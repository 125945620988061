import { useObserver } from "mobx-react";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useGlobalStore } from "../../global-store/GlobalStoreContext";
import { ArtGallery } from "../../pages/art-gallery/ArtGallery";
import { Contact } from "../../pages/contact/Contact";
import { Impressum } from "../../pages/impressum/Impressum";
import { Privacy } from "../../pages/privacy/Privacy";
import { PageContent } from "../page-content/PageContent";
import { PageHeader } from "../page-header/PageHeader";

import("./MainContent.css")

export const MainContent = () => {
    const adjustPageTitle = (newTitle) => {
        setPageTitle(newTitle)
    }

    const globalStore = useGlobalStore();
    const [pageTitle, setPageTitle] = useState("Willkommen");

    return useObserver(() => (
        <div onClick={() => {
            if (globalStore.isNavOpen) {
                globalStore.toggleNav();
            }
        }
        }
            className={globalStore.isNavOpen ? "main-content dark" : "main-content"}>
            <PageHeader pageTitle={pageTitle} />

            <PageContent>
                <Routes>
                    <Route path="contact" element={<Contact onMount={adjustPageTitle} />} />
                    <Route path="impressum" element={<Impressum onMount={adjustPageTitle} />} />
                    <Route path="privacy" element={<Privacy onMount={adjustPageTitle} />} />
                    <Route path="*" element={<ArtGallery onMount={adjustPageTitle} />} />
                </Routes>
            </PageContent>
        </div>
    )
    )
}
import React from "react";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";

import ("./BigPic.css")

export const BigPic = ({title, description, img}) => {
    return (
            <Card>
                <CardMedia id="card-image-container">
                    <img className="tt" alt={title + "_big"} src={img}/>
                </CardMedia>
                <CardContent>
                    <Typography align="center" className="card-text" gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography align="center" className="card-text" variant="h6" color="textSecondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
    );
}

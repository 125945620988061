import React from "react";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {MenuAndLogo} from "../menu-and-logo/MenuAndLogo";

import "./PageHeader.css"

export const PageHeader = ({pageTitle}) => {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color="transparent">
                <Toolbar className="header-container">
                    <MenuAndLogo />
                    <Typography align="center" className="page-title" variant="h4" component="div" sx={{flexGrow: 1}}>
                        {pageTitle}
                    </Typography>
                    <div className="empty-div"/>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

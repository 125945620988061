import React from "react";
import {createGlobalStore} from "./GlobalStore";
import {useLocalStore} from "mobx-react";

const GlobalStoreContext = React.createContext(null);

export const GlobalStoreProvider = ({children}) => {
    const globalStore = useLocalStore(createGlobalStore);

    return(
        <GlobalStoreContext.Provider value={globalStore}>
            {children}
        </GlobalStoreContext.Provider>
    )
}

export const useGlobalStore = () => React.useContext(GlobalStoreContext);